import styled from 'styled-components';

export const PlaylistCont = styled.div`
border:2px solid red;
display:flex;
flex-wrap:wrap;
margin:0 auto;
align-items:center;
justify-content:center;
margin:2%;
`


export const Top =styled.div`

color: blue
`

export const PlaylistItems=styled.div`
border:2px solid blue;
width:100%;
margin:0 auto;

`

export const Player=styled.div`
width:100%;
margin:2.5%;

`

export const PlayerItems=styled.div`
display:flex;
flex-direction:column;
margin:2.5%;
border:2px solid black;
align-items:center;
`